<template>
  <div>
    <p class="mt-5 mb-2 text-center font-size-26 text-black font-weight-bold">{{ $t('auth.resetPassword') }}</p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(addNewPassword)">
        <b-row>
          <b-col lg="12">
            <input-form
                class="mb-3"
                v-model="newPassword.reset_password_token"
                placeholder="######"
                :validate="'required'"
                :name="$t('auth.codeOtb')"
                :label="$t('endUser.otbCode')"
            />
          </b-col>
          <b-col lg="6">
            <input-form
                class="mb-3"
                v-model="newPassword.password"
                :validate="'required|min:8'"
                :placeholder="$t('auth.newPassword')"
                name="newPassword"
                :label="$t('auth.newPassword')"
                type="password"
            />
          </b-col>
          <b-col lg="6">
            <input-form
                class="mb-3"
                v-model="newPassword.confirm_password"
                :validate="'required|confirmed:newPassword'"
                :placeholder="$t('auth.passwordChecked')"
                :name="$t('auth.passwordChecked')"
                :label="$t('auth.passwordChecked')"
                type="password"
            />
          </b-col>
          <b-col lg="12">
            <div class="d-flex justify-content-center mt-4" >
              <b-button class="text-white w-20 py-2" variant="primary" type="submit" v-if="!submitLoading">
                <span>{{ $t('auth.save') }}</span>
              </b-button>
              <b-button v-else class=" text-white w-20 py-2" variant="primary" disabled>
                <spinner-loading text="saving"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import api from '@/modules/userProfile/services/userProfile'
import { core } from '@/config/pluginInit'

export default {
  name: 'resetPassword',
  data () {
    return {
      submitLoading: false,
      newPassword: {
        user: localStorage.getItem('verifyData') ? JSON.parse(localStorage.getItem('verifyData')).phone : '',
        country_code: localStorage.getItem('verifyData') ? JSON.parse(localStorage.getItem('verifyData')).country_code : '',
        reset_password_token: '',
        password: '',
        confirm_password: ''
      }
    }
  },
  methods: {
    addNewPassword () {
      this.submitLoading = true
      api.createPassword(this.newPassword).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'login' })
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>

<style>

</style>
